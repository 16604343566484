import React from 'react'
import classNames from 'classnames'
import Layout from 'layout/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Parallax from 'components/Parallax/Parallax.js'
import landingPageStyle from 'assets/jss/styles/pages/landingPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import LandingServices from 'pages-sections/landing/LandingServices'
import AireImg from "assets/img/sections/aire-acondicionado.jpg";

const useStyles = makeStyles(landingPageStyle);

const Aire = ({ data }) => {
  const classes = useStyles()

  return (
    <Layout
      pageTitle="❄ Instalación de aire acondicinado en Barcelona provincia"
      pageDescription="✨ Instalación y reparación de aire acondicinado en Barcelona provincia. Garraf, Sitges, Vilanova i la Geltrú y Vilafranca de Penedès."
      headerImage={AireImg}
      filter="dark"
      content={
        <React.Fragment>
          <h1 className={classes.typist}>Instalación y reparación de equipos de aire acondicinado</h1>

          <Link to="/presupuesto">
            <Button color={'primary'} className={classes.navButton}>
              Solicita Presupuesto
            </Button>
          </Link>

          <br />
        </React.Fragment>
      }
    >

      <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.mrBottom
          )}
        >
        <div dangerouslySetInnerHTML={{__html: data.aireJson.content.childMarkdownRemark.html}} />
      </GridItem>
  
      <LandingServices classes={classes} />

    </Layout>
  )  
}

export default Aire;

export const query = graphql`
  query AirepageQuery {
    aireJson {
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;